import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch,faEye,faEllipsisH,faEdit,faTrashAlt,faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Table,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import PlaceholderList from '../components/Placeholder';


import API from '../../config/Api';


import { Routes } from "../../routes";
import subjects from '../../data/subjects'



class ListAdvertisement extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading:true,
      page : 1,
      DisableLoadmore:false,
    }
    // this.HandleLoadMore(bind);
  }

  componentDidMount(){
    API.get(`advertisements`,{
      params:{page:this.state.page}
    }).then((response) => {
      let PageNumber = this.state.page
      this.setState({
      list:response.data.data,
      loading:false,
      page: PageNumber +1
      })
    });
    
  }

  HandleLoadMore = (e)=> {

    API.get(`advertisements`,{
      params:{page:this.state.page}
    }).then((response) => {
     if(response.data.data.length == 0){
       this.setState({
         DisableLoadmore:true
       })
     }
     let PageNumber = this.state.page
      this.setState({
      list:[...this.state.list,...response.data.data],
      page: PageNumber +1,
      loading:false
      })
    });
  }

  onDeleteHandle() {
    let id = arguments[0];

    let Confirm  = window.confirm('Do you want to delete this Record?');
    
    // (Confirm == true) ? : alert('Cencel ');
    
    if(Confirm){

          this.setState({
            loading:true
            })
        API.post('advertisement/delete',{id:id}).then((response)=>{
          console.log(response);

          API.get(`advertisements`,{
            params:{page:1}
          }).then((response) => {
            let PageNumber = 1
            this.setState({
            list:response.data.data,
            loading:false,
            page: PageNumber +1
            })
          });
        }).catch((e)=> {
          alert(e.response.data.message);
          this.setState({
            loading:false
            })
        })
      }

  }
  


  render(){

    const totalsubjects = this.state.list.length;
    const TableRow = (props) => {
    const { id,product_id,web_image,phone_image,external_link,type,createdAt,Product } = props;

    return (
      <tr>
        <td>
          <span className="fw-normal">
            <a href={web_image} target="_blank">Web Image</a> 
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <a href={phone_image} target="_blank">Phone Image</a> 
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {type}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {(external_link) ? <a href={external_link} target="_blank">External link</a> : '-'} 
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(createdAt).format('Do MMM YY')}
          </span>
        </td>
        <td>
          {(Product) ? <Card.Link as={Link} to={`/product/${product_id}`} className="fw-normal"> View Product </Card.Link> : '-' }
          
        </td>
        <td>
          {(Product) ? <Card.Link as={Link} to={`/user/${Product.User.uuid}`} className="fw-normal">View User </Card.Link> : '-' }
          {/* <Card.Link as={Link} to={`/user/${Product.User.uuid}`} className="fw-normal">
            <Button > View User </Button> 
          </Card.Link> */}
        </td>
        <td>
             <a onClick={this.onDeleteHandle.bind(this, id)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
             </ a>
           
        </td>
      </tr>
    );
  };

  let alert = this.props.location.state ? this.props.location.state :  '';


    return (<>
      {
        
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Advertisement List</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Advertisement List</h4>
          <p className="mb-0">All Advertisements List</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

    
     <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Web Image</th>
              <th className="border-bottom">Phone Image</th>
              <th className="border-bottom">Type</th>
              <th className="border-bottom">External link</th>
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">Product</th>
              <th className="border-bottom">User</th>
              <th className="border-bottom">Delete</th>
            </tr>
          </thead>
          <tbody>
          { (this.state.loading == true) ? <PlaceholderList colspan='4' /> : this.state.list.map(t => <TableRow  {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalsubjects}</b> entries
          </small>
          {(this.state.DisableLoadmore) ? '' : 
          <Button  onClick={this.HandleLoadMore} > 
            Load more 
          </Button> }
          
        </Card.Footer>
      </Card.Body>
    </Card>
    
     
    
    
     
    </>);
  }

}


export default ListAdvertisement



