import React from "react";
import { Col, Row, Card, Image, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom'
import { uploadFile } from 'react-s3';
import { Braket } from "aws-sdk";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    // ['link', 'image'],
    // ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

const  MyInputs = (props) => {

  switch (props.type) {
    case 'file':
      
    return (
    <Col md={6} key={props.index} className="mb-3">      
    <Form.Label>{props.lable}</Form.Label>
      <Form.Control onChange={props.submithandle}  name={props.name}   type={props.type}  placeholder={`Enter your ${props.lable}`} />

    <Error message={props.error} />
   </Col>)
    break;

    case 'checkbox':
      return (
        <Col md={6} key={props.index} className="mb-3">      
        <Form.Label>{props.lable}</Form.Label>
         <Form.Check  label="Active"  type="checkbox" checked={props.value} onChange={props.submithandle}  name={props.name}  
            placeholder={`Enter your ${props.lable}`} />
            
        <Error message={props.error} />
       </Col>)
       break;
  
  
    default:

        return (
          <Col md={6} key={props.index} className="mb-3">      
          <Form.Label>{props.lable}</Form.Label>
           
           <Form.Control onChange={props.submithandle}  name={props.name}  
            type={props.type}  value={props.value} placeholder={`Enter your ${props.lable}`} />
          <Error message={props.error} />
         </Col>)
  }



}

const form  = [
  {
    lable : 'Title',
    name :'blog_title',
    type :'text',
    required:true
   },
   {
     lable : 'Author Name',
     name :'blog_author',
     type :'text',
     required:true
    },
    {
    lable : 'Blog Image',
    name :'blog_image',
    type :'file',
    required:true
   }
];
const config = {
  bucketName: "yellowishs3bucket",
  dirName: "admin/category",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP4XTBPTL",
  secretAccessKey: "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"
}

class EditBlog extends React.Component{

  constructor(props){
    super(props);
    this.state={
      blog_title:'',
      blog_image:'',
      blog_author:'',
      blog_text:'',
      error:{},
      success:false,
      loading:false,
      success:false
    } 
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(value) {
    this.setState({ blog_text: value })
    console.log(this.state.text)
  }


  componentDidMount(){

    let data = {params:{id:this.props.match.params.id}};
    console.log('id is : ',this.props.match.params.id)
    Api.get('blog',data).then((response)=>{
      console.log(response);
      this.setState({
        blog_title:response.data.data.title,
        blog_image:response.data.data.image,
        blog_author:response.data.data.author,
        blog_text:response.data.data.blog_text,
      })
    }).catch((e)=> {
        // this.setState({
        // error:e.response.data.message
      // })
    })
    
  }

  
  handleChangeinput = (e) => {
    // e.preventDefault();
    var InputName = e.target.name;
    var InputType = e.target.type
   
    console.log(InputName,[InputType,e.target.checked])
     
    switch (InputType) {
      case 'file':
        this.setState({loading:true})
        uploadFile(e.target.files[0], config)
        .then(data => this.setState({ [InputName]:data.location,loading:false }))
        .catch(err => console.error(err))
      break;

      case 'checkbox':
      // console.log(InputName,e.target.checked)
      console.log('InputName','e.target.checked')

        this.setState({ [InputName]:e.target.checked})

      break;
  
      default:
        this.setState({ [InputName]:e.target.value })
      }
    }


  handleSubmit = e => {
    console.log(' We hove errors ')
    e.preventDefault();
    let Errors = [];
    this.setState({process:true})
    form.map((value,index)=> {
      if(value.required){
          console.log(this.state[value.name]);
        if(this.state[value.name] == ''){

          Errors[value.name] = `Please enter ${value.lable}`;
          console.log(value.name,Errors[value.name]);
          this.state.error[value.name] = Errors[value.name]
        }else{
          delete this.state.error[value.name];         
        }
      };
      if(form.length == (index+1)){
        this.setState({process:false})
      }
    })

    if(Object.keys(this.state.error).length){

      console.log(' We hove errors ',this.state.error)
    }else{
      this.setState({error:{}});
      var data =  {
        blog_title:this.state.blog_title,
        blog_image:this.state.blog_image,
        blog_author:this.state.blog_author,
        blog_text:this.state.blog_text,
      }
      console.log('with',data)
      Api.put('blog',data,{params:{id:this.props.match.params.id}}).then((response)=> {
        this.setState({success:true})
      }).catch((e)=>{
        let message = e.response.data.message;
        this.setState({error:{submit: message}})
      })

     

    }

    


  }

  render(){

   










    if(this.state.success){
      return <Redirect  to={{
              pathname: '/blogs',
              state: "Subejct Upadetd Successfully"
            }} />
    }
     return (
       
      <Row className="mt-4"  >
        <Col xs={8} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <Form  >
                  <Row>
                  {form.map((value,index)=> {
                    return (
                      <MyInputs index={index} submithandle ={this.handleChangeinput.bind(this)} name={value.name}  lable={value.lable} type={value.type}  value={this.state[value.name]} />
                  
                );


                  })}
                  <label>Blog Content</label>
                  <ReactQuill value={this.state.blog_text}
                    modules={modules}
                    formats={formats}
                    onChange={this.handleChange} />
                  </Row>
                    <Col md={12} className="mt-4">
                        <Button style={{marginTop:'50px'}} variant="primary"  onClick={this.handleSubmit} type="button">Update </Button>
                    </Col>
                </Form>
              </Card.Body>
            </Card>
        </Col>
        <Col xs={4} xl={4}>
            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                  <Image src={this.state.blog_image} />
                </Card.Body>
            </Card>
        </Col>
      </Row>);
  }

}



export default EditBlog;
