import React from 'react'
import { Icon, Label, Menu, Table} from 'semantic-ui-react'
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

const TableRow = (props) => {
    const { serial_number,product_id,product_type,manufacturer,model,year,location,condition,listing_type,description,price} = props;

    return (
        <Table.Row>
        <Table.Cell>{product_id}</Table.Cell>
        <Table.Cell>{product_type}</Table.Cell>
        <Table.Cell>{listing_type}</Table.Cell>
        <Table.Cell>{price}</Table.Cell>
        {/* <Table.Cell>{model}</Table.Cell> */}
        <Table.Cell>
          <Card.Link as={Link} to={`/product/${product_id}`} className="fw-normal">
            <Button > View </Button> 
          </Card.Link>
        </Table.Cell>
      </Table.Row>
    );
  };

const ChatList = (props) => (
    <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Product Id</Table.HeaderCell>
        <Table.HeaderCell>Product Type </Table.HeaderCell>
        <Table.HeaderCell>Listing Type</Table.HeaderCell>
        <Table.HeaderCell>Price </Table.HeaderCell>
        <Table.HeaderCell>Details </Table.HeaderCell>
        {/* <Table.HeaderCell>Model </Table.HeaderCell> */}
      </Table.Row>
    </Table.Header>

    <Table.Body>


    { (props.chat.length == 0 ) ? ' No Record found' :  props.chat.map((t) => {
      t.variant = props.variant ;
      return <TableRow {...t}/>;
    })}
      
    </Table.Body>

    
  </Table>
)

export default ChatList
