import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from "react-router-dom";
import { Routes } from "../routes";


// pages

import DashboardOverview from "./dashboard/DashboardOverview";
import UserList from './User/UserList'
import ProductList from './User/ProductList'

import ComplaintList from './enquiry/ListComplaint'
import EnquiryList from './enquiry/ListEnquiry'


import UserView from './User/UserView'
import QueryView from './Quotes/QueryView'
import ProductView from './User/ProductView'
import ProductEdit from './User/ProductEdit'
import ChatView from './Chat/Chatview'
import ScrollToTop from "../components/ScrollToTop";



import BlogAdd from './Blogs/CreateBlog'
import BlogEdit from './Blogs/EditBlog'
import BlogList from './Blogs/ListBlog'

import CategoryList from './Category/ListCategory'
import ListNewCategory from './Category/ListNewCategory'
import CategoryAdd from './Category/CreateCategory'
import CategoryEdit from './Category/EditCategory'


import AdvertisementList from './Advertisement/ListAdvertisement'
import AdvertisementAdd from  './Advertisement/CreateAdvertisement'
import AdvertisementExternalAdd from  './Advertisement/CreateExternalAdvertisement'
import AdvertisementEdit from './Advertisement/EditAdvertisement'


import FinanceOuotesList from './Quotes/FinanceOuotesList'
import InsuranceOuotesList from './Quotes/InsuranceOuotesList'
import TransportationOuotesList from './Quotes/TransportationOuotesList'
import TransportationDetailsView from './Quotes/TransportationQuoteView'
import SendTransportationQuote from './Quotes/SendTransportationQuote'

import UserQueryList from './Quotes/QueryList'
import NewsLetterList from './Quotes/NewsLetterList'

// import IndustryTypeList from './IndustryType/ListIndustryType'
// import IndustryTypeAdd from './IndustryType/CreateIndustryType'
// import IndustryTypeEdit from './IndustryType/EditIndustryType'

import CountryAdd from './Country/CreateCountry'
import CountryEdit from './Country/EditCountry'
import CountryList from './Country/ListCountry'

import Signin from "./auth/Signin";
import Lock from "./auth/Lock";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";





// documentation pages


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { extend } from 'chartist';



const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        {/* <Preloader show={loaded ? false : true} /> */}
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};


class HomePage extends React.Component{
  render(){

let token = localStorage.getItem('token');
if(token == 'false' || token == '' || token == null) {
return (
  <HashRouter>
      <ScrollToTop />
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
     <Redirect to={Routes.Signin.path} />
  </Switch>
  </HashRouter>);
}

 return(
  <HashRouter>
      <ScrollToTop />
  <Switch>

    {/*  Auth   */}
   
    
    {/*  Dashboard  */}

    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={UserList} />

    <RouteWithSidebar exact path={Routes.UserList.path} component={UserList} />
    <RouteWithSidebar exact path={Routes.ProductList.path} component={ProductList} />

    <RouteWithSidebar exact path={Routes.ComplaintList.path} component={ComplaintList} />
    <RouteWithSidebar exact path={Routes.EnquiriesList.path} component={EnquiryList} />


    <RouteWithSidebar exact path={Routes.UserView.path} component={UserView} />
    <RouteWithSidebar exact path={Routes.QueryView.path} component={QueryView} />
    <RouteWithSidebar exact path={Routes.ProductView.path} component={ProductView} />
    <RouteWithSidebar exact path={Routes.ProductEdit.path} component={ProductEdit} />
       
    <RouteWithSidebar exact path={Routes.ChatView.path} component={ChatView} />

 

    <RouteWithSidebar exact path={Routes.BlogAdd.path} component={BlogAdd} />
    <RouteWithSidebar exact path={Routes.BlogEdit.path} component={BlogEdit} />
    <RouteWithSidebar exact path={Routes.BlogView.path} component={BlogList} />

    
    <RouteWithSidebar exact path={Routes.CategoryAdd.path} component={CategoryAdd} />
    <RouteWithSidebar exact path={Routes.NewCategoryView.path} component={ListNewCategory} />
    <RouteWithSidebar exact path={Routes.CategoryView.path} component={CategoryList} />
    <RouteWithSidebar exact path={Routes.CategoryEdit.path} component={CategoryEdit} />


   
    {/* Qoutes */}
    <RouteWithSidebar exact path={Routes.InsuranceListView.path} component={InsuranceOuotesList} />
    <RouteWithSidebar exact path={Routes.FinanceListView.path} component={FinanceOuotesList} />
    <RouteWithSidebar exact path={Routes.TransportationListView.path} component={TransportationOuotesList} />
    <RouteWithSidebar exact path={Routes.TransportationDetailsView.path} component={TransportationDetailsView} />
    <RouteWithSidebar exact path={Routes.SendTransportationQuote.path} component={SendTransportationQuote} />
    
    <RouteWithSidebar exact path={Routes.UserQueryView.path} component={UserQueryList} />
    <RouteWithSidebar exact path={Routes.NewsLetterList.path} component={NewsLetterList} />

    
    
    <RouteWithSidebar exact path={Routes.CountryAdd.path} component={CountryAdd} />
    <RouteWithSidebar exact path={Routes.CountryEdit.path} component={CountryEdit} />
    <RouteWithSidebar exact path={Routes.CountryView.path} component={CountryList} />

    
    <RouteWithSidebar exact path={Routes.AdvertisementAdd.path} component={AdvertisementAdd} />
    <RouteWithSidebar exact path={Routes.AdvertisementExternalAdd.path} component={AdvertisementExternalAdd} />
    <RouteWithSidebar exact path={Routes.AdvertisementEdit.path} component={AdvertisementEdit} />
    <RouteWithSidebar exact path={Routes.AdvertisementView.path} component={AdvertisementList} />

{/* 
    <RouteWithSidebar exact path={Routes.IndustryTypeAdd.path} component={IndustryTypeAdd} />
    <RouteWithSidebar exact path={Routes.IndustryTypeEdit.path} component={IndustryTypeEdit} />
    <RouteWithSidebar exact path={Routes.IndustryTypeView.path} component={IndustryTypeList} /> */}
    
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 
    <Redirect to={Routes.UserList.path} />
  </Switch>
  </HashRouter>
);
  }
}

export default HomePage;


// export default () => {
// const [token, setToken] = useState();
// if(!token) {
// return (
//   <BrowserRouter>
//       <ScrollToTop />
//   <Switch>
//     <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
//      <Redirect to={Routes.Signin.path} />
//   </Switch>
//   </BrowserRouter>);
   
// }

//  return(
//   <BrowserRouter>
//       <ScrollToTop />
//   <Switch>

//     {/*  Auth   */}
//     <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    
//     {/*  Dashboard  */}

//     <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
//     <RouteWithSidebar exact path={Routes.UserList.path} component={UserList} />
//     <RouteWithSidebar exact path={Routes.TeacherList.path} component={TeacherList} />

//     <RouteWithSidebar exact path={Routes.SubjectAdd.path} component={SubjectAdd} />
//     <RouteWithSidebar exact path={Routes.SubjectEdit.path} component={SubjectEdit} />
//     <RouteWithSidebar exact path={Routes.SubjectView.path} component={SubjectList} />
    

//     <RouteWithSidebar exact path={Routes.CountryAdd.path} component={CountryAdd} />
//     <RouteWithSidebar exact path={Routes.CountryEdit.path} component={CountryEdit} />
//     <RouteWithSidebar exact path={Routes.CountryView.path} component={CountryList} />
    
//     <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />

//     <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
//     <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 
//     <Redirect to={Routes.NotFound.path} />
//   </Switch>
//   </BrowserRouter>
// );
//  }
