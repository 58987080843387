import React from "react";
import { Col, Row, Card, Form, Button,Image, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom';
import { uploadFile } from 'react-s3';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    // ['link', 'image'],
    // ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

const form  = [
  {
    lable : 'Title',
    name :'blog_title',
    type :'text',
    required:true
   },
   {
     lable : 'Author Name',
     name :'blog_author',
     type :'text',
     required:true
    },
    {
    lable : 'Featured Image (size 1200 X 500)',
    name :'blog_image',
    type :'file',
    required:true
   }
];

const config = {
  bucketName: "yellowishs3bucket",
  dirName: "admin/blogs",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP4XTBPTL",
  secretAccessKey: "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"
}

class CreateBlog extends React.Component{

  constructor(props){
    super(props);
    this.state={
      blog_title:'',
      blog_image:'',
      blog_author:'',
      blog_text:'',
      error:{},
      process:false,
      loading:false,
      success:false
    }
    this.handleChange = this.handleChange.bind(this)

  }

  handleChange(value) {
    this.setState({ blog_text: value })
    console.log(this.state.text)
  }

  componentDidMount(){
    
  }

  HandleSelect =  (value) => {
    console.log(value);
    this.setState({SelectedManufacturer:value})
  }

  handleChangeinput = (e) => {
    var InputName = e.target.name;
    var InputType = e.target.type
   
     
    console.log(InputName,e.target.value)
    switch (InputType) {
      case 'file':
        this.setState({loading:true})
        uploadFile(e.target.files[0], config)
        .then(data => this.setState({ [InputName]:data.location,loading:false }))
        .catch(err => console.error(err))
      break;
  
      default:
        this.setState({ [InputName]:e.target.value })
      }
    }

    

  handlename = e=> {this.setState({category_name:e.target.value})}
  handleSubmit = e => {
    e.preventDefault();
    // this.setState({error:{}})

    // if(this.state.process === false){

    // }else{

    // }

    let Errors = [];
    this.setState({process:true})
    // // this.setState
    form.map((value,index)=> {
      if(value.required){
          console.log(this.state[value.name]);
        if(this.state[value.name] == ''){

          Errors[value.name] = `Please enter ${value.lable}`;
          console.log(value.name,Errors[value.name]);
          this.state.error[value.name] = Errors[value.name]
        }else{
          delete this.state.error[value.name];         
        }

      };
      if(form.length == (index+1)){
        this.setState({process:false})
      }
    })



    // this.setState({error:Errors});
    // this.state.error.push('jiedjeidjiewjdw');
    // co
    console.log('Statte is  :  ',this.state)
    console.log('Error  :  ',Errors)
    console.log('Error  :  ',this.state.error)

    if(Object.keys(this.state.error).length){
      console.log(' We hove errors ',Object.keys(this.state.error).length)
    }else{  
      var data = {
        blog_title:this.state.blog_title,
        blog_image:this.state.blog_image,
        blog_author:this.state.blog_author,
        blog_text:this.state.blog_text,
      }
      console.log(data)
      this.setState({loading:true})
      Api.post('add-blog',data).then((response)=> {
        console.log(response)
        this.setState({success:true,loading:false})
      }).catch((e)=>{
        console.log(e.response)
        // let message = e.response.data.message;
        this.setState({loading:false})
      })
    }


      // this.setState({error:''});
      

  }

  render(){
    if(this.state.success){
      return <Redirect  to={{
              pathname: '/blogs',
              state: "Advertisement Created Successfully"
            }} />
    }
     return (
       
      <Row className="mt-4"  >
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <Form onSubmit={this.handleSubmit} >
                  <Row>

                  {form.map((value,index)=> {
                    return (
                    <Col md={12} key={index} className="mb-3">      
                      <Form.Label>{value.lable}</Form.Label>
                      <Form.Control onChange={this.handleChangeinput} name={value.name}   type={value.type} placeholder={`Enter your ${value.lable}`} />
                      <Error message={this.state.error[value.name]} />
                  </Col>
                );


                  })}

                    {/* <Col md={6} className="mb-3">      
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control onChange={this.handleChangeinput} name="category_name"  required type="text" placeholder="Enter your category name" />
                        <Error message={this.state.error} />
                    </Col>


                    <Col md={6} className="mb-3">      
                        <Form.Label>Category Image</Form.Label>
                        <Form.Control onChange={this.handleChangeinput}  name='category_image' required type="file" placeholder="Select your category Image" />
                        <Error message={this.state.error} />
                    </Col>

                    <Col md={6} className="mb-3">      
                        <Form.Label>Category Description</Form.Label>
                        <Form.Control onChange={this.handleChangeinput} name='category_description' required type="text" placeholder="Enter your category description" />
                        <Error message={this.state.error} />
                    </Col> */}
                    <label>Blog Content</label>
                    <ReactQuill value={this.state.blog_text}
                      modules={modules}
                      formats={formats}
                      onChange={this.handleChange} />

                  </Row>
                    <Col md={12} className="mt-4">
                        <Button  style={{marginTop:'50px'}} disabled={this.state.loading} variant="primary"  type="submit"> {(this.state.loading) ? 'Loading ...' : 'Save'} </Button>
                    </Col>
                </Form>
              </Card.Body>
            </Card>
        </Col>
        <Col xs={4} xl={4}>
            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                  <Image src={this.state.blog_image} />
                </Card.Body>
            </Card>
        </Col>
      </Row>);
  }

}



export default CreateBlog;
