import React from "react";
import { Col, Row, Card, Form, Button, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom'
import { uploadFile } from 'react-s3';
import { Braket } from "aws-sdk";
const  MyInputs = (props) => {

  switch (props.type) {
    case 'file':
      
    return (
    <Col md={12} key={props.index} className="mb-3">      
    <Form.Label>{props.lable}</Form.Label>
      <Form.Control onChange={props.submithandle}  name={props.name}   type={props.type}  placeholder={`Enter your ${props.lable}`} />

    <Error message={props.error} />
   </Col>)
    break;

    case 'checkbox':
      return (
        <Col md={12} key={props.index} className="mb-3">      
        <Form.Label>{props.lable}</Form.Label>
         <Form.Check  label="Active"  type="checkbox" checked={props.value} onChange={props.submithandle}  name={props.name}  
            placeholder={`Enter your ${props.lable}`} />
            
        <Error message={props.error} />
       </Col>)
       break;
  
  
    default:

        return (
          <Col md={12} key={props.index} className="mb-3">      
          <Form.Label>{props.lable}</Form.Label>
           
           <Form.Control onChange={props.submithandle}  name={props.name}  
            type={props.type}  value={props.value} placeholder={`Enter your ${props.lable}`} />
          <Error message={props.error} />
         </Col>)
  }



}

const form  = [
  {
    lable : 'Serial Number',
    name :'serial_number',
    type :'text',
    required:true
   },
   {
     lable : 'Model',
     name :'model',
     type :'text',
     required:true
    },
    {
      lable : 'Year',
      name :'year',
      type :'text',
      required:true
     }
];
const config = {
  
}

class ProductEdit extends React.Component{

  constructor(props){
    super(props);
    this.state={
      product_id:'',
      serial_number:'',
      model:'',
      year:'',
      manufacturer:'',
      category_name:'',
      error:{},
      success:false,
      loading:false,
      success: false,
      categories_level1: [],
      categories_level2: [],
      categories_level3: [],
      categories_level4: [],
      category:''
    } 
  }

  componentDidMount(){

    let data = {params:{product_id:this.props.match.params.id}};
    Api.get('user/product',data).then((response)=>{
      console.log(response);
      this.setState({
        serial_number: response.data.data.serial_number,
        model:response.data.data.model,
        year: response.data.data.year,
        category_name : response.data.data.category_name,
        manufacturer : response.data.data.manufacturer,
      })
      console.log({
        serial_number: response.data.data.serial_number,
        model:response.data.data.model,
        year:response.data.data.year
      })
    }).catch((e)=> {
        // this.setState({
        // error:e.response.data.message
      // })
    })
    this.setState({
      loading : true
    })
    Api.get('list-new-category',data).then((response)=>{
      console.log(response);
      this.setState({
        categories_level1: response.data.data,
        loading : false
      })
    }).catch((e)=> {
        // this.setState({
        // error:e.response.data.message
      // })
    })
    
  }

  handleChangeCategorylevel1 = (e) => { 
    var index = e.target.value;
    console.log(this.state.categories_level1[index])
    if (this.state.categories_level1[index].categories.length > 0) {
      this.setState({
        categories_level2: this.state.categories_level1[index].categories,
        categories_level3: [],
        categories_level4:[],
        loading : false
      })
    }else {
      this.setState({
        category: this.state.categories_level1[index].id,
        categories_level3: [],
        categories_level4:[],
        categories_level2:[],
        loading: false
      })
    }
  }

  handleChangeCategorylevel2 = (e) => { 
    var index = e.target.value;
    if (this.state.categories_level2[index].categories.length > 0) {
      this.setState({
        categories_level3: this.state.categories_level2[index].categories,
        categories_level4:[],
        loading : false
      })
    } else {
      this.setState({
        category: this.state.categories_level2[index].id,
        categories_level3: [],
        categories_level4:[],
        loading: false
      })
    }
  }

  handleChangeCategorylevel3 = (e) => { 
    var index = e.target.value;
    if (this.state.categories_level3[index].categories.length > 0) {
      this.setState({
        categories_level4: this.state.categories_level3[index].categories,
        loading : false
      })
    }else {
      this.setState({
        category: this.state.categories_level3[index].id,
        categories_level4:[],
        loading: false
      })
    }
  }

  handleChangeCategorylevel4 = (e) => { 
    var index = e.target.value;
    this.setState({
      category: this.state.categories_level4[index].id,
      loading: false
    });
    
    console.log('Selected category',this.state.category);
  }

  
  handleChangeinput = (e) => {
    // e.preventDefault();
    var InputName = e.target.name;
    var InputType = e.target.type
   
    console.log(InputName,[InputType,e.target.checked])
     
    switch (InputType) {
      case 'file':
        this.setState({loading:true})
        uploadFile(e.target.files[0], config)
        .then(data => this.setState({ [InputName]:data.location,loading:false }))
        .catch(err => console.error(err))
      break;

      case 'checkbox':
      // console.log(InputName,e.target.checked)
      console.log('InputName','e.target.checked')

        this.setState({ [InputName]:e.target.checked})

      break;
  
      default:
        this.setState({ [InputName]:e.target.value })
      }
    }


  handleSubmit = e => {
    


    e.preventDefault();
    let Errors = [];
    this.setState({process:true})
    form.map((value,index)=> {
      if(value.required){
          console.log(this.state[value.name]);
        if(this.state[value.name] == ''){

          Errors[value.name] = `Please enter ${value.lable}`;
          console.log(value.name,Errors[value.name]);
          this.state.error[value.name] = Errors[value.name]
        }else{
          delete this.state.error[value.name];         
        }
      };
      if(form.length == (index+1)){
        this.setState({process:false})
      }
    })



    if(Object.keys(this.state.error).length){

      console.log(' We hove errors ',this.state.error)
    }else{
      this.setState({error:{}});
      var data = {
        serial_number: this.state.serial_number,
        model: this.state.model,
        year: this.state.year,
        category: this.state.category
      }
      Api.put('product/update-product',data,{params:{product_id:this.props.match.params.id}}).then((response)=> {
        this.setState({success:true})
      }).catch((e)=>{
        let message = e.response.data.message;
        this.setState({error:{submit: message}})
      })

    }

    


  }

  render(){

    if(this.state.success){
      return <Redirect  to={{
              pathname: '/products',
              state: "Product Updated Successfully"
            }} />
    }
     return (
       
      <Row className="mt-4"  >
      {
        (this.state.categories_level1.length > 0) ?
        <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm mb-4">
                 <Card.Body>
                <div>Manufacturer : {this.state.manufacturer}</div>  
                   <br></br> 
                <div>Category : {this.state.category_name}</div>   
                   <br></br>
                <Form  >
                  <Row>
                  {form.map((value,index)=> {
                    return (
                      <MyInputs index={index} submithandle ={this.handleChangeinput.bind(this)} name={value.name}  lable={value.lable} type={value.type}  value={this.state[value.name]} />
                    );
                  })}
                  </Row>
                  <Row>
                       <Form.Label>Category</Form.Label>
                       <Col md={3} className="mb-3"> 
                        <Form.Select aria-label="Default select example" onChange={this.handleChangeCategorylevel1}>
                          <option value='0'>Select</option>
                          {this.state.categories_level1.map((value,index)=> {
                            return (
                              <>
                              <option value={index}>{value.name}</option>
                              </>
                            );
                          })}
                        </Form.Select> 
                      </Col>
                      {
                         (this.state.categories_level2.length > 0) ?  
                         <Col md={3} className="mb-3"> 
                             <Form.Select aria-label="Default select example" onChange={this.handleChangeCategorylevel2}>
                             <option value='0'>Select</option>
                            {this.state.categories_level2.map((value,index)=> {
                            return (
                             <>
                             <option value={index}>{value.name}</option>
                             </>
                           );
                         })}
                       </Form.Select></Col> : ''       
                       }
                       {
                         (this.state.categories_level3.length > 0) ?  
                         <Col md={3} className="mb-3"> 
                         <Form.Select aria-label="Default select example" onChange={this.handleChangeCategorylevel3}>
                         <option value='0'>Select</option>
                         {this.state.categories_level3.map((value,index)=> {
                           return (
                             <>
                             <option value={index}>{value.name}</option>
                             </>
                           );
                         })}
                       </Form.Select></Col> : ''       
                       }
                       {
                         (this.state.categories_level4.length > 0) ?  
                         <Col md={3} className="mb-3"> 
                         <Form.Select aria-label="Default select example" onChange={this.handleChangeCategorylevel4}>
                         <option value='0'>Select</option>
                         {this.state.categories_level4.map((value,index)=> {
                           return (
                             <>
                             <option value={index}>{value.name}</option>
                             </>
                           );
                         })}
                       </Form.Select></Col> : ''       
                      }
                   </Row>
                    <Col md={12} className="mt-4">
                        <Button variant="primary" disabled={(this.state.category)?'':'disabled'}  onClick={this.handleSubmit} type="button">Update </Button>
                    </Col>
                </Form>
              </Card.Body>
            </Card>
        </Col>: <Spinner animation="border" />
      }
      </Row>);
  }

}



export default ProductEdit;
