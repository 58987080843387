import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser, faHome, faPlus,faEye,faEllipsisH,faEdit,faBackward,faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Image, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Table,Nav,Pagination,Alert,Accordion } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import PlaceholderList from '../components/Placeholder';

import API from '../../config/Api';


import { Routes } from "../../routes";
import subjects from '../../data/subjects'
import _ from "lodash"



class ListNewCategory extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading:true,
      parent:0,
      level:1,
      history:[],
      page : 1,
      DisableLoadmore:false,
    }
    // this.HandleLoadMore(bind);
  }

  componentDidMount(){
    API.get(`list-new-category`,{
      params:{page:this.state.page}
    }).then((response) => {
      let PageNumber = this.state.page
      this.setState({
      list:response.data.data,
      loading:false,
      page: PageNumber +1
      })
    });
    
  }


  HandleLoadMore = (e)=> {

    API.get(`list-new-category`,{
      params:{page:this.state.page}
    }).then((response) => {
     if(response.data.data.length == 0){
       this.setState({
         DisableLoadmore:true
       })
     }
     let PageNumber = this.state.page
      this.setState({
      list:[...this.state.list,...response.data.data],
      page: PageNumber +1,
      loading:false
      })
    });
  }
  goForward() {
    this.setState({
      loading:true,
    });
    let id = arguments[0];
    let child = _.find(this.state.list, {id:id});
    console.log(id)
    let history = this.state.history;
    let level = this.state.level;
    this.setState({
      loading:false,
      list:child.categories,
      parent:id,
      level: level+1,
      history:[...history,[child.name+child.level]]
    });
    
    console.log(child)
    console.log(this.state)
  }

  goBack() {
    this.setState({
      loading:true,
    });
    API.get(`category-detail`,{
      params:{id:this.state.parent}
    }).then((response) => {
     if(response.data.data.length == 0){
       this.setState({
         DisableLoadmore:true
       })
     }
     console.log(response)
     let history = this.state.history;
     let level = this.state.level;
     history.pop();
     let PageNumber = this.state.page
      this.setState({
      list:response.data.data,
      parent: response.data.data[0].parent_id,
      page: PageNumber +1,
      history: history,
      level:level-1,
      loading:false
      })
    });
  }

  render(){

    const totalsubjects = this.state.list.length;
    const TableRow = (props) => {
    const { id,category_id,parent_id, level, name,active, categories,image ,createdAt } = props;

    return (<>
      <tr>
        {/* <td>{category_id}</td> */}
        <td>{name}</td>
        <td>{level}</td>
        <td>{(image)?<Image style={{height : "70px"}} src={image} thumbnail/>:'-'}</td>
        <td>{active.toString()}</td>
        <td>
            <Card.Link as={Link} to={`/category/${category_id}`} className="fw-normal">
              <Button ><FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Button>
            </Card.Link>
        </td>
        <td>
          {(level < 6 && active)?<Button onClick={this.goForward.bind(this, id)} variant='success'> Open </Button>:'-'}
         
        </td>
      </tr>

          </>
    );
  };

  let alert = this.props.location.state ? this.props.location.state :  '';


    return (<>
      {
        
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div>
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>New Categories</Breadcrumb.Item>
        { (this.state.history == true) ? <PlaceholderList colspan='4' /> : this.state.history.map((t) => 
          <Breadcrumb.Item>{t}</Breadcrumb.Item>
          )}
        </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {(this.state.parent > 0)?<Button onClick={this.goBack.bind(this, this.state.parent)} variant='success'><FontAwesomeIcon icon={faBackward} className="me-2" />Go Back</Button> : ''}
          {(this.state.level < 6 )? <Card.Link as={Link} to={`/category/add/${this.state.parent}`} className="fw-normal"><Button ><FontAwesomeIcon icon={faPlus} className="me-2" /> Add</Button></Card.Link> : ''}
           
          
          
        </div>
      </div>
        
      <Table striped bordered hover>
      <thead>
        <tr>
          {/* <th>#</th> */}
          <th>Category Name</th>
          <th>Level</th>
          <th>Icon</th>
          <th>Active</th>
          <th>Edit</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
          { (this.state.loading == true) ? <PlaceholderList colspan='4' /> : this.state.list.map((t) => 
          <TableRow {...t} />
          )}
      </tbody>
        </Table>
          {(this.state.list.length > 0 ) ? '' :<Alert variant='danger'>No Records Found
          </Alert>}
    </>);
  }

}


export default ListNewCategory



