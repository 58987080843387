import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Image } from 'semantic-ui-react'



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      {/* <td> */}
        <p style={{wordWrap: "break-word"}}>{props.value}</p>
        {/* </td>  */}
  </tr>
);



const ShowDetails = (props) => ( <div>
  <Card.Link as={Link} to={`/advertisement/add/${props.data.product_id}`} className="fw-normal">
       <Button > Post Advertisement for this product </Button> 
   </Card.Link> 
             
  <table class="table">
  <tbody>
  {(props.data.listing_type == "selling")?<MyRow lable="Serial number" value={(props.data.serial_number)??'-'}/>:''}
  
    <MyRow 
    lable="Product id"
    value={props.data.product_id} />

<MyRow 
    lable="Product type"
    value={props.data.product_type} />

<MyRow 
    lable="Model"
    value={props.data.model} />
    
  {(props.data.listing_type == "selling")?<MyRow lable="Year" value={(props.data.year)??'-'} />:''}
    <MyRow 
    lable="Location"
    value={props.data.location} />
    <MyRow 
    lable="Description"
    value={props.data.description} />
    {(props.data.listing_type == "selling")?<MyRow lable="Price" value={props.data.price}/>:''}
    <MyRow 
    lable="Listing type"
    value={props.data.listing_type} />
    <MyRow 
    lable="Category"
    value={props.data.category.name} />
    <MyRow 
    lable="Email"
    value={props.data.user_email} />
    <MyRow 
    lable="Phone"
    value={props.data.user_phone} />
    <MyRow 
    lable="Alternate phone"
    value={props.data.user_alternate_phone} />
    <MyRow 
    lable="User Address"
    value={props.data.user_address} />
  {/* <MyRow 
    lable="product_type"
    value={(props.data.verified)?'Verified':'Not Verified'} /> */}
    

    {/* {(props.data.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.data.subjects} /> : ''} */}
    
    
    {/* <MyRow 
    lable="Company Name"
    value={props.data.company.company_name} /> */}

    {/* <MyRow 
    lable="Address"
    value={props.data.company.address} />
    <MyRow 
    lable="Country"
    value={props.data.company.country} />
    <MyRow 
    lable="City"
    value={props.data.company.city} />
    <MyRow 
    lable="State"
    value={props.data.company.state} />
    <MyRow 
    lable="Postcode"
    value={props.data.company.postcode} /> */}
    
    {/* {(props.data.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.data.description} /> : ''}
    
    {(props.data.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.data.avg_rating }</td>
  </tr>: ''} */}


 
 

 <br></br>
 



  </tbody>
</table>

 <Image.Group size='small'>
     {props.data.ProductImages.map((image, i) =>  <Image src={image.image}/>)}
   
  </Image.Group>
     </div>
)

export default ShowDetails


