import React from "react";
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import ShowProductdetails from '../components/ShowProductdetails';
import Api from '../../config/Api';
import PlaceholderList from '../components/Placeholder'; import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faEdit } from "@fortawesome/free-solid-svg-icons";
import API from '../../config/Api';


class ProductView extends React.Component{

  constructor(props){
    super(props);
    this.state={
      data:[],
      error:'',
      success:false,
      loading:true
    } 
  }

  componentDidMount(){
    // let data = {id:this.props.match.params.id};
    let data = {params:{product_id:this.props.match.params.id}};
    Api.get('user/product',data).then((response)=>{
      console.log(response);
      this.setState({
        data:response.data.data,
        loading:false
      })
      console.log(this.state.data);
    }).catch((e)=> {
      console.log(e);

        this.setState({
        // error:e.response.data,
        loading:false
      })
    })

  }

  onClickActive() {
    let id = arguments[0];
    
    
    let Confirm  = window.confirm('Do you want to approve this product?');
    
    if(Confirm){
      this.setState({
        loading:true
        })
      API.post('user/product/approve',{product_id:id}).then((response)=>{
        let data = {params:{product_id:this.props.match.params.id}};
        Api.get('user/product',data).then((response)=>{
          console.log(response);
          this.setState({
            data:response.data.data,
            loading:false
          })
          console.log(this.state.data);
        }).catch((e)=> {
          console.log(e);

            this.setState({
            // error:e.response.data,
            loading:false
          })
        })
      }).catch((e)=> {
        alert(e.response.data.message);
      })
    }

  }


  render(){
    if(this.state.loading === true){
      return (<table><PlaceholderList colspan ="colspan" /></table>);
    }
     return (
       
      <Row className="mt-4" >
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Product Details <br></br><br></br> {(this.state.data.approved) ? <h3>Approved <FontAwesomeIcon icon={faCheckCircle} /></h3> : 
            <Card.Link as={Link} to={`/product-edit/${this.props.match.params.id}`} className="fw-normal"> <Button ><FontAwesomeIcon icon={faEdit} className="me-2" /> Approve </Button> </Card.Link> }</Card.Header>
              <Card.Body>
              <ShowProductdetails  
              variant = 'user'
              data={this.state.data } 
              />
              </Card.Body>
            </Card>
        </Col>
       

      </Row>);
  }

}



export default ProductView;
