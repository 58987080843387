import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom';
import { uploadFile } from 'react-s3';
import Select from 'react-select';

const form  = [
  {
    lable : 'Web Image',
    name :'web_image',
    type :'file',
    required:true
   },{
    lable : 'Phone Image',
    name :'phone_image',
    type :'file',
    required:true
   },{
    lable : 'External Link (eg. https://yellowish.io/)',
    name :'external_link',
    type :'text',
    required:true
   }
];

const config = {
  bucketName: "yellowishs3bucket",
  dirName: "admin/category",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP4XTBPTL",
  secretAccessKey: "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"
}

class CreateExternalAdvertisement extends React.Component{

  constructor(props){
    super(props);
    this.state={
      category_name:'',
      category_image:'',
      category_description:'',
      external_link: '',
      manufacturer :[],
      SelectedManufacturer : [],
      error:{},
      process:false,
      loading:false,
      success:false
    }

  }

  componentDidMount(){
    
  }

  HandleSelect =  (value) => {
    // console.log(value);
    // this.setState({SelectedManufacturer:value})
  }

  handleChangeinput = (e) => {
    var InputName = e.target.name;
    var InputType = e.target.type
   
     
    console.log(InputName,e.target.value)
    switch (InputType) {
      case 'file':
        this.setState({loading:true})
        uploadFile(e.target.files[0], config)
        .then(data => this.setState({ [InputName]:data.location,loading:false }))
        .catch(err => console.error(err))
      break;
  
      default:
        this.setState({ [InputName]:e.target.value })
      }
    }

    

  handlename = e=> {this.setState({category_name:e.target.value})}
  handleSubmit = e => {
    e.preventDefault();
    // this.setState({error:{}})

    // if(this.state.process === false){

    // }else{

    // }

    let Errors = [];
    this.setState({process:true})
    // // this.setState
    form.map((value,index)=> {
      if(value.required){
          console.log(this.state[value.name]);
        if(this.state[value.name] == ''){

          Errors[value.name] = `Please enter ${value.lable}`;
          console.log(value.name,Errors[value.name]);
          this.state.error[value.name] = Errors[value.name]
        }else{
          delete this.state.error[value.name];         
        }

      };
      if(form.length == (index+1)){
        this.setState({process:false})
      }
    })

    console.log('Statte is  :  ',this.state)
    console.log('Error  :  ',Errors)
    console.log('Error  :  ',this.state.error)

    if(Object.keys(this.state.error).length){
      console.log(' We hove errors ',Object.keys(this.state.error).length)
    }else{  
      var data = {
        type:'External',
        external_link:this.state.external_link,
        web_image:this.state.web_image,
        phone_image:this.state.phone_image,
      }
      console.log(data)
      this.setState({loading:true})
      Api.post('add-advertisement',data).then((response)=> {
        console.log(response)
        this.setState({success:true,loading:false})
      }).catch((e)=>{
        console.log(e.response)
        // let message = e.response.data.message;
        this.setState({loading:false})
      })
    }


      // this.setState({error:''});
      

  }

  render(){
    if(this.state.success){
      return <Redirect  to={{
              pathname: '/advertisements',
              state: "Advertisement Created Successfully"
            }} />
    }
     return (
       
      <Row className="mt-4"  >
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <Form onSubmit={this.handleSubmit} >
                  <Row>

                  {form.map((value,index)=> {
                    return (
                    <Col md={12} key={index} className="mb-3">      
                      <Form.Label>{value.lable}</Form.Label>
                      <Form.Control onChange={this.handleChangeinput} name={value.name}   type={value.type} placeholder={`Enter your ${value.lable}`} />
                      <Error message={this.state.error[value.name]} />
                  </Col>
                );


                  })}

                    {/* <Col md={6} className="mb-3">      
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control onChange={this.handleChangeinput} name="category_name"  required type="text" placeholder="Enter your category name" />
                        <Error message={this.state.error} />
                    </Col>


                    <Col md={6} className="mb-3">      
                        <Form.Label>Category Image</Form.Label>
                        <Form.Control onChange={this.handleChangeinput}  name='category_image' required type="file" placeholder="Select your category Image" />
                        <Error message={this.state.error} />
                    </Col>

                    <Col md={6} className="mb-3">      
                        <Form.Label>Category Description</Form.Label>
                        <Form.Control onChange={this.handleChangeinput} name='category_description' required type="text" placeholder="Enter your category description" />
                        <Error message={this.state.error} />
                    </Col> */}

                    <Col md={12} className="mt-4">
                        <Button disabled={this.state.loading} variant="primary"  type="submit"> {(this.state.loading) ? 'Loading ...' : 'Save'} </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
        </Col>
      </Row>);
  }

}



export default CreateExternalAdvertisement;
