import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import ShowDetails from '../components/Showdetails';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom';
import { Image } from 'semantic-ui-react'
import PlaceholderList from './../components/Placeholder';  
import ChatList from './../components/Chatlist';  


class UserView extends React.Component{

  constructor(props){
    super(props);
    this.state={
      data:[],
      error:'',
      success:false,
      loading:true
    } 
  }

  componentDidMount(){
    // let data = {id:this.props.match.params.id};
    let data = {params:{id:this.props.match.params.id}};
    Api.get('user',data).then((response)=>{
      console.log(response);
      this.setState({
        data:response.data.data,
        loading:false
      })
      console.log(this.state.data);
    }).catch((e)=> {
      console.log(e)
        this.setState({
        error:e.response.data.message,
        loading:false
      })
    })

  }


  render(){
    if(this.state.loading === true){
      return (<table><PlaceholderList colspan ="colspan" /></table>);
    }
     return (
       
      <Row className="mt-4"  >
        {/* <Col xs={12} xl="4" >
          <Card border="light" className="bg-white shadow-sm mb-4 ">
          <Card.Header>Profile Image</Card.Header>
          <Card.Img height="310px" variant="top" src={(this.state.data.image == null) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.data.image  } />
         
          </Card>
        </Col> */}
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>User Details</Card.Header>
              <Card.Body>
              <ShowDetails  
              variant = 'user'
              name={this.state.data.first_name+' '+this.state.data.last_name } 
              email={this.state.data.email }
              verified={this.state.data.verified }
              company={this.state.data.Company}
              />
              </Card.Body>
            </Card>
        </Col>
        <Col xs={12} xl={10}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Products ({this.state.data.products.length})</Card.Header>
              <Card.Body>

              <ChatList variant={1} chat={this.state.data.products }  ></ChatList>
              </Card.Body>
            </Card>
        </Col>

      </Row>);
  }

}



export default UserView;
