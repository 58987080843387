import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'


import { faStar } from '@fortawesome/free-solid-svg-icons';



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
       {/* <td> */}
       <p style={{wordWrap: "break-word"}}>{props.value}</p>
        {/* </td>  */}
  </tr>
);



const ShowQuerydetails = (props) => (
  <table class="table">
  <tbody>
    
  <MyRow 
    lable="Name"
    value={(props.data.first_name)??"N/A"} />
    
    <MyRow 
    lable="Subject"
        value={(props.data.subject) ?? 'N/A'} />
      
  <MyRow 
    lable="Query Type"
    value={(props.data.query_type)??'N/A'} />  

    <MyRow 
    lable="Description"
    value={(props.data.description)??'N/A'} />

<MyRow 
    lable="Created On"
    value={(props.data.createdAt)??'N/A'} />
  </tbody>
</table>
)

export default ShowQuerydetails


