import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'


import { faStar } from '@fortawesome/free-solid-svg-icons';



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
       {/* <td> */}
       <p style={{wordWrap: "break-word"}}>{props.value}</p>
        {/* </td>  */}
  </tr>
);



const ShowTransportationQuoteDetails = (props) => (
  <table class="table">
  <tbody>

  <MyRow 
    lable="Name"
    value={(props.data.User.first_name+' '+props.data.User.last_name)??"-"} />

  <MyRow 
    lable="Email"
    value={(props.data.User.email)??"-"} />

    <MyRow 
      lable="quote_status"
      value={(props.data.quote_status)??'-'} />
    
  <MyRow 
    lable="Value"
    value={(props.data.value)??"-"} />
    
    <MyRow 
    lable="Machine Running"
    value={props.data.machine_running.toString()} />

    <MyRow 
    lable="Pickup Location"
    value={(props.data.pickup_location)??'-'} />

    <MyRow 
    lable="Date"
    value={props.data.date} />  


    <MyRow 
    lable="Drop Location"
    value={props.data.drop_location} />  

    <MyRow 
    lable="Description"
    value={(props.data.description)??'-'} />

    <MyRow 
      lable="Created On"
      value={(props.data.createdAt)??'-'} />

    <MyRow 
      lable="Quoted Amount"
      value={(props.data.quote_amount)??'-'} />

    <MyRow 
      lable="Quoted Details"
      value={(props.data.quote_description)??'-'} />


  </tbody>
</table>
)

export default ShowTransportationQuoteDetails


